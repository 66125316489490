import cn from "classnames";
import { Button, Card } from "react-bootstrap";

import { Phone } from "@/ui/Phone";
import classes from "./styles.module.scss";

export default function ({ className, name, address, phone, google_maps_link, size = "default" }) {
  return (
    <Card className={cn(className, classes.OfficeCard)} data-size={size}>
      <Card.Header>
        <h6>{name}</h6>
      </Card.Header>
      <Card.Body>
        <address dangerouslySetInnerHTML={{ __html: address }} className="mb-0" />
        {google_maps_link && (
          <Button href={google_maps_link} size="sm" variant="link">
            View on Google Maps
          </Button>
        )}
      </Card.Body>
      <Card.Footer>
        {phone.map((p, idx) => (
          <dl className="d-flex mb-1 gap-1" key={idx}>
            <dt className="mb-0">{p.label}:</dt>
            <dd className="mb-0">
              <Phone phone={p.phone} />
            </dd>
          </dl>
        ))}
      </Card.Footer>
    </Card>
  );
}
